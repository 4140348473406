import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import './Footer.css';

import { mq } from "../utils/media-queries"
import ExternalLink from "../components/ExternalLink"
import pfizerFooterLogo from "../assets/images/pfizer-logo-182x74.png"
import ytIcon from "../assets/images/ftr-youtube.png"
import fbIcon from "../assets/images/ftr-facebook.png"
import igIcon from "../assets/images/ftr-instagram.png"

const Footer = () => {
  return (
    <footer>
      <section
        css={css`
          padding: 40px 0 0;
          background-color: #ebebeb;
        `}
      >
        <Container>
          <Row>
            <Col
              lg={8}
              css={css`
                border-right: 0.5px solid #8c8d91;
                padding-right: 50px;
                font-size: 13px;
                line-height: 17px;
              `}
            >
              <p>
                This site is intended only for U.S. residents. The health information contained herein is provided for educational purposes only and is not intended to replace discussions with a healthcare provider. Logos are the trademarks of their respective owners. All decisions regarding patient care must be made with a healthcare provider considering the unique characteristics of the patient.
              </p>
              <div
                css={css`
                  span {
                    display: inline-block;
                    :not(:last-of-type) {
                      margin-right: 20px;
                    }
                  }
                `}
              >
                <span>Copyright © 2024 Pfizer Inc.</span>
                <span>All rights reserved.</span>
                <span>US-DIS-24-105-MT</span>
              </div>
              {/* <nav
                css={css`
                  margin-top: 16px;
                `}
              >
                <ul
                  className="orig-links"
                  css={css`
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    a {
                      text-decoration: none;
                      color: inherit;
                    }
                    li {
                      cursor: pointer;
                    }
                  `}
                >
                  <li
                    css={css`
                      margin-right: 5px;
                    `}
                  >
                    <ExternalLink
                      externalUrl={"http://www.seattlegenetics.com/privacy"}
                    >
                      Privacy policy
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalUrl={"https://www.seagen.com/terms"}
                    >
                      Legal notice
                    </ExternalLink>
                  </li>
                </ul>
              </nav> */}
            </Col>

            <Col
              lg={4}
              css={css`
                padding-left: 50px;
                ${[mq[2]]} {
                  padding-left: 15px;
                  padding-top: 20px;
                }
              `}
            >
              <p
                css={css`
                  font-size: 25px;
                  font-weight: bold;
                  line-height: 34px;
                  width: 125px;
                `}
              >
                Follow us:
              </p>
              <div
                className="soci-links"
                css={css`
                  width: 125px;
                  display: flex;
                  justify-content: space-between;
                  img {
                    width: 50px;
                  }
                `}
              >
                <ExternalLink
                  externalUrl="https://www.youtube.com/channel/UCqPUResBNU2k8PDR83OqgNw"
                  data-testid="footer_link_ytLink"
                >
                  <img loading='lazy' alt="youtube" src={ytIcon} />
                </ExternalLink>
                <ExternalLink
                  externalUrl="https://www.facebook.com/HodgkinHub/"
                  data-testid="footer_link_fbLink"
                >
                  <img loading='lazy' alt="facebook" src={fbIcon} />
                </ExternalLink>
                <ExternalLink
                  externalUrl="https://www.instagram.com/hodgkinhub/"
                  data-testid="footer_link_igLink"
                >
                  <img loading='lazy' alt="instagram" src={igIcon} />
                </ExternalLink>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        css={css`
          padding: 40px 0;
          background-color: #ebebeb;
        `}
      >
        <Container>
          <div className="row align-items-center justify-content-between footer-bottom">
            <div className="col-12 col-md-auto">
              <ExternalLink
                externalUrl="https://www.pfizer.com/"
                data-testid="footer_link_logoLink"
              >
                <img
                  loading='lazy'
                  className='footer-logo'
                  alt="Pfizer logo"
                  src={pfizerFooterLogo}
                />
              </ExternalLink>
            </div>
            <div className="col-auto">
              <div className="row align-items-end">
                {/* <div className="col-auto label3 mb-8 mb-md-0">
                  This website is intended for U.S. residents only.
                </div>
                <div className="col-12 col-md-auto copy-right label3">
                  ©2023 Seagen Inc.
                </div> */}
                <div className="col">
                  <ul className="privacy">
                    <li>
                      <ExternalLink
                        externalUrl="https://www.seagen.com/privacy">Privacy Policy
                      </ExternalLink>
                    </li>
                    <li>
                      <ExternalLink
                        externalUrl="https://www.seagen.com/terms">Terms of Use
                      </ExternalLink>
                    </li>
                    <li>
                      <ExternalLink
                        externalUrl="https://www.seagen.com/contact">Contact
                      </ExternalLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </footer>
  )
}

// Footer.propTypes = {}

export default Footer
