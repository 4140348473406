import React from "react"
import { useEffect } from 'react';
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
import { useStateValue } from "../state/state"

const ExitRamp = () => {
  const [{ isExitRampOpen, externalUrl }, dispatch] = useStateValue()

  useEffect(() => {
    document.addEventListener('click', function handleClick(event) {
      const isExternalLink = event.target.classList.contains('external-link-class');
      if (isExternalLink) {
        event.preventDefault();
        dispatch({
          type: "toggleExitRamp",
          isExitRampOpen: true,
          externalUrl: event.target.href,
        })
      }
    });
  })

  return isExitRampOpen ? (
    <div
      css={css`
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.31);
        z-index: 20000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          background-color: #ffffff;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
          width: 711px;
          height: 371px;
          padding: 42px 34px;
          ${[mq[1]]} {
            width: 100%;
            height: auto;
          }
        `}
      >
        <div
          css={css`
            font-size: 21px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 50px;
          `}
        >
          You are now leaving HodgkinHub.com. You will be taken to the website
          for a treatment for certain types of Hodgkin lymphoma (HL). The
          content on this site is not endorsed by any partner organizations
          affiliated with HogkinHub.com.
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              width: 220px;
              height: 90px;
              border-radius: 8px;
              background: linear-gradient(180deg, #ae58cf 0%, #7e3f97 100%);
              color: #ffffff;
              font-size: 14px;
              line-height: 16px;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            ${[mq[2]]} {
              div {
                width: 140px;
              }
            }
            ${[mq[0]]} {
              justify-content: space-evenly;
            }
          `}
        >
          <a
            css={css`
              &:hover {
                text-decoration: none;
              }
            `}
            onClick={() =>
              dispatch({
                type: "toggleExitRamp",
                isExitRampOpen: false,
                externalUrl: "",
              })
            }
            href={externalUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>Continue</div>
          </a>
          <div
            css={css`
              margin-left: 32px;
              ${[mq[0]]} {
                margin: 0;
              }
            `}
            onClick={() =>
              dispatch({
                type: "toggleExitRamp",
                isExitRampOpen: false,
                externalUrl: "",
              })
            }
          >
            Stay on page
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default ExitRamp
