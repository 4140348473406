import React from 'react'


const MedicalWebPageSchema = () => {

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `{ "@context": "https://schema.org", "@type": "MedicalWebPage", "about": { "@type": "MedicalCondition", "name": [ "Hodgkin Lymphoma", "Lymphocyte-depleted Hodgkin's lymphoma" ] }, "mainContentOfPage": [ "Diagnosis", "Treatment" ], "audience": "https://schema.org/Patient", "specialty": "https://schema.org/Oncologic" }` }} />

  )
}

export default MedicalWebPageSchema
