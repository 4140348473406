import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import { useStateValue } from "../state/state"

const ExternalLink = ({ children, externalUrl }) => {
  const [, dispatch] = useStateValue()
  return (
    <a
      css={css`
        &:hover {
          text-decoration: none;
        }
      `}
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()
        dispatch({
          type: "toggleExitRamp",
          isExitRampOpen: true,
          externalUrl: externalUrl,
        })
      }}
    >
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  externalUrl: PropTypes.string.isRequired,
}

export default ExternalLink
