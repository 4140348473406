import React from 'react'


const MedicalConditionSchema = () => {

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `{"@context":"https://schema.org","@type":"MedicalCondition","alternateName":"Hodgkin Lymphoma","associatedAnatomy":{"@type":"AnatomicalStructure","name":"lymphatic system"},"code":{"@type":"MedicalCode","code":"C81.99","codingSystem":"ICD-10-CM"},"differentialDiagnosis":{"@type":"DDxElement","diagnosis":{"@type":"MedicalCondition","name":"Progressive Transformation of Germinal Centres, Castleman's Disease, Histiocytoses, Lymphomatoid Papulosis, Angiocentric Immunoproliferative Lesion"},"distinguishingSign":[{"@type":"MedicalSymptom","name":"fatigue, fever, loss of appetite"},{"@type":"MedicalSymptom","name":"shortness of breath, swollen lymph nodes, weight loss"},{"@type":"MedicalSymptom","name":"night sweats, severe itching"}]},"riskFactor":[{"@type":"MedicalRiskFactor","name":"age"},{"@type":"MedicalRiskFactor","name":"gender"},{"@type":"MedicalRiskFactor","name":"family history"},{"@type":"MedicalRiskFactor","name":"Epstein-Barr virus (EBV) infection"},{"@type":"MedicalRiskFactor","name":"HIV infection"},{"@type":"MedicalRiskFactor","name":"weakened immune system"}],"signOrSymptom":[{"@type":"MedicalSymptom","name":"fatigue, fever, loss of appetite, night sweats, severe itching"},{"@type":"MedicalSymptom","name":"shortness of breath, swollen lymph nodes, weight loss"}]}` }} />

  )
}

export default MedicalConditionSchema
