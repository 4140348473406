import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
/* 
See readme regarding partners image
*/

import partnersImg from "../assets/images/partners.png"

const Partners = () => {
  return (
    <article>
      <Container
        css={css`
          border-top: 2px solid #D8D8D8;
          padding: 40px 0 0;
          margin-top: 20px;
          ${[mq[2]]} {
            margin-top: 20px;
          }
          img {
            width: 100%;
          }
          h2 {
            font: bold 22px Arial, sans-serif;
            margin-bottom: 0;
          }
        `}
      >
        <h2>HODGKIN HUB PARTNERS</h2>
        <Row>
          <Col>
            <img
              loading='lazy'
              css={css`
                margin: 20px 0 40px;
                ${[mq[2]]} {
                  margin: 20px 0;
                }
              `}
              src={partnersImg}
              alt="partners"
            />
          </Col>
        </Row>
      </Container>
    </article>
  )
}

export default Partners
